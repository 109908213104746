<script>
import { alertController } from "@ionic/vue";

export default {
  name: "MixinsIonicAlert",
  methods: {
    async alert({ header, subHeader, message, buttons }) {

      if (!buttons) buttons = ["OK"];

      const alert = await alertController.create({
        header,
        subHeader,
        message,
        buttons,
      });
      await alert.present();
    },
  },
};
</script>
