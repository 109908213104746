import mutations from "./mutations";
import * as getters from "./getters";
import * as actions from "./actions";

import Ls from "../../../services/ls.js";
import * as keys from "../../../services/ls-keys.js";

const initialState = {
  saves: Ls.get(keys.SAVE_SAVES) ? Ls.get(keys.SAVE_SAVES) : [],
  current_save_id: Ls.get(keys.SAVE_CURRENT_SAVE) ? Ls.get(keys.SAVE_CURRENT_SAVE) : null,
  recent_transactions: [],
  report_data: {
    assets: [],
    monthly_summary: {},
  },
};

export default {
  namespaced: true,
  state: () => initialState,
  getters,
  mutations,
  actions,
};
