export const saves = (state) => {
  const saves = {};
  if (state.saves.forEach) {
    state.saves.forEach((item) => {
      saves[item.save_id] = { ...item };
    });
  }
  return saves;
};

export const savesCount = (state) => {
  return Object.keys(state.saves).length;
};

export const currentSaveId = (state) => state.current_save_id;

export const currentSavePreferences = (state, getters) => {
  if (!getters.currentSaveId || !getters.saves[getters.currentSaveId])
    return null;

  return getters.saves[getters.currentSaveId].preferences;
};

export const currentSavePrefFavAcc = (state, getters) => {
  return getters.currentSavePreferences === null ||
    !getters.currentSavePreferences.favAcc
    ? {}
    : getters.currentSavePreferences.favAcc;
};

export const currentSavePrefCustomReports = (state, getters) => {
  if (getters.currentSavePreferences === null || !getters.currentSavePreferences.cusRep)
    return {};

  const hashed = {};
  for (const cr of getters.currentSavePreferences.cusRep) {
    hashed[cr.id] = { ...cr };
  }
  return hashed;
};

export const currentSaveName = (state, getters) => {
  if (!state.current_save_id || !getters.saves[state.current_save_id]) {
    return false;
  }
  return getters.saves[state.current_save_id].save_name;
};

export const recentTransactions = (state) => state.recent_transactions;

export const saveBalance = (state) => {
  if (!state.report_data || !state.report_data.assets) {
    return null;
  }

  return state.report_data.assets.reduce((carry, asset) => {
    return carry + asset.balance;
  }, 0);
};
