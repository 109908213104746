import * as SC from "./store/modules/save/constants.js";
import { modalController } from "@ionic/vue";
import ModalMultipleAccountsPicker from "./views/components/ModalMultipleAccountsPicker.vue";

export const numberFormatComma = (
  number,
  precision_digits = 2,
  fillZeroDecimal = false
) => {
  if (typeof number === "undefined") return null;
  if (number === null) return null;

  let num = number.toString();
  let precision = "";
  // has decimal
  if (num.indexOf(".") !== -1) {
    const pow = Math.pow(10, precision_digits);
    number = Math.round(number * pow) / pow;
    num = number.toString();
  }
  if (num.indexOf(".") !== -1) {
    precision = num.substr(num.indexOf("."));
    num = num.substr(0, num.indexOf("."));
  }

  if (fillZeroDecimal) {
    if (precision === "") {
      precision = ".";
    }
    if (precision.length < precision_digits + 1) {
      precision += "0".repeat(precision_digits + 1 - precision.length);
    }
  }

  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + precision;
};

export const extractAxiosErrorResponse = (e) => {
  if (e.response && e.response.data) {
    const subHeader = e.response.data.message
      ? e.response.data.message
      : "Invalid input";
    let message = null;
    for (const key in e.response.data.errors) {
      message = e.response.data.errors[key].reduce(
        (carry, each) => `${carry}${each}<br/>`,
        ""
      );
    }
    return { subHeader, message };
  }
  return false;
};

export const inputModes = () => [
  {
    mode: "expense",
    label: "Expense",
    color: "danger",
    acc1Label: "จ่ายด้วย",
    acc2Label: "หมวดหมู่",
    acc1Type: "asset",
    acc2Type: "category",
    acc1FavAcc: SC.PREF_FAVACC_ASSET_FOR_EXPENSE,
    acc2FavAcc: SC.PREF_FAVACC_CATEGORY_FOR_EXPENSE,
  },
  {
    mode: "transfer",
    label: "Transfer",
    color: "primary",
    acc1Label: "โอนจาก",
    acc2Label: "ไปยัง",
    acc1Type: "asset",
    acc2Type: "asset",
    acc1FavAcc: SC.PREF_FAVACC_ASSET_FOR_FROM,
    acc2FavAcc: SC.PREF_FAVACC_ASSET_FOR_TO,
  },
  {
    mode: "income",
    label: "Income",
    color: "success",
    acc1Label: "รับเข้า",
    acc2Label: "หมวดหมู่",
    acc1Type: "asset",
    acc2Type: "category",
    acc1FavAcc: SC.PREF_FAVACC_ASSET_FOR_INCOME,
    acc2FavAcc: SC.PREF_FAVACC_CATEGORY_FOR_INCOME,
  },
];

export const isCategory = (account) =>
  account.account_type === 3 ||
  account.account_type === 4 ||
  account.account_type === 5 ||
  account.account_type === 6 ||
  account.account_type === 7;

export const closeModal = () => {
  return modalController.dismiss();
};

export const openMultipleAccountPicker = async ({
  accounts,
  selectedItems,
  categoryMode,
  onReceivedData,
  maxItems,
  clearFilterAfterSelected,
}) => {

  if (typeof clearFilterAfterSelected === "undefined")
    clearFilterAfterSelected = true;

  const modal = await modalController.create({
    component: ModalMultipleAccountsPicker,
    componentProps: {
      accounts,
      selectedItems,
      categoryMode,
      maxItems,
      clearFilterAfterSelected,
    },
    swipeToClose: false,
  });

  modal.onWillDismiss().then((recv) => {
    if (recv.data && Array.isArray(recv.data)) {
      if (onReceivedData) {
        onReceivedData(recv.data);
      }
    }
  });

  return modal.present();
};

export const extractCustomReport = (custom_report_id, currentSavePrefCustomReports) => {
  let custom_report = null;
  if (custom_report_id) {
    for (const i of Object.keys(currentSavePrefCustomReports)) {
      if (currentSavePrefCustomReports[i].id === custom_report_id) {
        custom_report = { ...currentSavePrefCustomReports[i] };
        break;
      }
    }
  }
  const hashedCR = {};
  if (custom_report) {
    for (const item of custom_report.items) {
      hashedCR[item.i] = item.c; // id => color
    }
  }
  return {
    custom_report,
    hashedCR,
  };
};
