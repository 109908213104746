import * as types from "./mutation-types.js";

export const loadAccounts = async ({ commit, rootGetters }) => {
  const save_id = rootGetters["save/currentSaveId"];

  if (!save_id) {
    return;
  }

  const response = await window.axios.get(`/api/saves/${save_id}/accounts`);
  commit(types.SET_ACCOUNTS, response.data.data);
  commit(types.SET_TRANSACTION_CHANGED, false);
};

export const editAccount = async (
  { commit, rootGetters },
  payload
) => {
  const save_id = rootGetters["save/currentSaveId"];
  if (!save_id) return;

  const mutate_payload = {...payload};
  const account_id = mutate_payload.account_id;
  delete mutate_payload.account_id;

  const response = await window.axios.put(
    `/api/saves/${save_id}/accounts/${account_id}`,
    mutate_payload
  );

  commit(types.REPLACE_PARTIAL_ACCOUNTS, [response.data]);
};

export const createAccount = async ({ commit, rootGetters }, payload) => {
  const save_id = rootGetters["save/currentSaveId"];
  if (!save_id) return;

  const response = await window.axios.post(
    `/api/saves/${save_id}/accounts`,
    payload
  );

  commit(types.PUSH_ACCOUNT, response.data);
};

export const deleteAccount = async ({ commit }, account) => {
  const save_id = account.save_id;
  const account_id = account.account_id;
  await window.axios.delete(`/api/saves/${save_id}/accounts/${account_id}`);
  commit(types.REMOVE_ACCOUNT, account);
};
