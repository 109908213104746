import * as types from "./mutation-types.js";
import Ls from "../../../services/ls.js";
import * as keys from "../../../services/ls-keys.js";

export default {
  [types.SET_ACCOUNTS](state, accounts) {
    state.accounts = [...accounts];
    Ls.set(keys.ACCOUNT_ACCOUNTS, accounts);
  },

  [types.PUSH_ACCOUNT](state, account) {
    if (!account.account_id) return;

    // duplicated account_id
    for (const acc of state.accounts) {
      if (acc.account_id === account.account_id) {
        return;
      }
    }

    state.accounts.push(account);
    Ls.set(keys.ACCOUNT_ACCOUNTS, state.accounts);
  },

  [types.REPLACE_PARTIAL_ACCOUNTS](state, accounts) {
    if (accounts.length > 0) {
      const draft = [...state.accounts];
      const mapping = {};
      draft.forEach((acc, i) => {
        mapping[acc.account_id] = i;
      });
      accounts.forEach(acc => {
        if (typeof mapping[acc.account_id] === "undefined") {
          draft.push(acc);
        } else {
          draft[mapping[acc.account_id]] = { ...acc };
        }
      });
      state.accounts = [...draft];
      Ls.set(keys.ACCOUNT_ACCOUNTS, draft);
    }
  },

  [types.SET_TRANSACTION_CHANGED](state, flag) {
    state.transaction_changed = flag;
  },

  [types.REMOVE_ACCOUNT](state, account) {
    state.accounts = state.accounts.filter(
      (acc) => acc.account_id !== account.account_id
    );
    state.transaction_changed = state.accounts;
  },
};
