import mutations from "./mutations";
import * as getters from "./getters";
import * as actions from "./actions";

import Ls from "../../../services/ls.js";
import * as keys from "../../../services/ls-keys.js";

const initialState = {
  accounts: Ls.get(keys.ACCOUNT_ACCOUNTS) ? Ls.get(keys.ACCOUNT_ACCOUNTS) : [],
  transaction_changed: false, // มีการเพิ่ม หรือแก้ไขรายการ แต่ยังไม่ได้โหลด accounts ใหม่หรือไม่
};

export default {
  namespaced: true,
  state: () => initialState,
  getters,
  mutations,
  actions
};
