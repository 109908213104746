
import {
  isPlatform,
  // Ionic Components
  IonApp,
  IonAvatar,
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";
import { defineComponent, ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import {
  pieChartOutline as reportOutline,
  pieChartSharp as reportSharp,
  fingerPrintOutline as iconLoginOutline,
  fingerPrintSharp as iconLoginSharp,
  createOutline as inputOutline,
  createSharp as inputSharp,
  reloadOutline as iconReload,
  diceOutline as categoryOutline,
  diceSharp as categorySharp,
  gridOutline as assetOutline,
  gridSharp as assetSharp,
  settingsOutline,
  settingsSharp,
  informationCircleOutline,
  informationCircleSharp,
} from "ionicons/icons";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonAvatar,
    IonButton,
    IonContent,
    IonFooter,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane,
    IonGrid,
    IonRow,
    IonCol,
  },
  setup() {
    const appPages = [
      {
        title: "บันทึกข้อมูล",
        url: { name: "home" },
        iosIcon: inputOutline,
        mdIcon: inputSharp,
      },
      {
        title: "บัญชี",
        url: { name: "assets" },
        iosIcon: assetOutline,
        mdIcon: assetSharp,
      },
      {
        title: "หมวดหมู่",
        url: { name: "categories" },
        iosIcon: categoryOutline,
        mdIcon: categorySharp,
      },
      {
        title: "รายงานสรุป",
        url: { name: "report", params: { chart: "expenses" } },
        iosIcon: reportOutline,
        mdIcon: reportSharp,
      },
      {
        title: "ตั้งค่า",
        url: { name: "settings" },
        iosIcon: settingsOutline,
        mdIcon: settingsSharp,
      },
    ];

    const route = useRoute();
    const selectedIndex = computed((): number => {
      return appPages.findIndex(
        (page) => page.url.name === route.name
      );
    });

    const routerOutlet = ref(null);

    let lastActiveTime = Date.now();
    const store = useStore();

    const currentSaveName = computed((): string => {
      return store.getters["save/currentSaveName"];
    });

    onMounted(() => {
      // @ts-ignore
      if (routerOutlet.value && routerOutlet.value.$el) {
        // @ts-ignore
        routerOutlet.value.$el.swipeGesture = false;
      }
      store.dispatch("initializeUserData");

      // check session if leave for 10 minutes.
      window.addEventListener("touchstart", () => {
        const now = Date.now();
        if (now - lastActiveTime > 600000) {
          store.dispatch("auth/checkSession");
        }
        lastActiveTime = Date.now();
      });
    });

    const hardReload = () => {
      window.location.reload();
    };

    const iOnMenuSwipeGesture = isPlatform("iphone") ? "false" : "true";

    return {
      // computed
      currentSaveName,
      selectedIndex,
      // data
      appPages,
      isSelected: (url: string) => (url === route.path ? "selected" : ""),
      // icons
      iconLoginOutline,
      iconLoginSharp,
      helpOutline: informationCircleOutline,
      helpSharp: informationCircleSharp,
      // const
      iOnMenuSwipeGesture,
      // methods
      hardReload,
      iconReload,
      routerOutlet,
    };
  }
});
