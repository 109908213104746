import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: { name: "home" },
  },
  {
    name: "help",
    path: "/app/help",
    component: () => import("../views/pages/help/Main.vue"),
  },
  {
    name: "home",
    path: "/app",
    meta: { requiredAuth: true },
    component: () => import("../views/pages/input/Main.vue"),
  },
  {
    name: "login",
    path: "/app/login",
    component: () => import("../views/Login.vue"),
  },
  {
    name: "tx", // tx stand for transactions
    props: true,
    path: "/app/tx/:id",
    meta: { requiredAuth: true },
    component: () => import("../views/pages/account/Transactions.vue"),
  },
  {
    name: "assets",
    path: "/app/assets",
    meta: { requiredAuth: true },
    component: () => import("../views/pages/account/Assets.vue"),
  },
  {
    name: "categories",
    path: "/app/categories",
    meta: { requiredAuth: true },
    component: () => import("../views/pages/account/Categories.vue"),
  },
  {
    name: "report",
    props: true,
    path: "/app/report/:chart",
    meta: { requiredAuth: true },
    component: () => import("../views/pages/report/Report.vue"),
  },
  {
    name: "settings",
    path: "/app/settings",
    meta: { requiredAuth: true },
    component: () => import("../views/pages/settings/Settings.vue"),
  },
  {
    name: "settings-favoriteAccounts",
    path: "/app/settings/favorite",
    meta: { requiredAuth: true },
    component: () => import("../views/pages/settings/FavoriteAccounts.vue"),
  },
  {
    name: "settings-favoriteAccountsPicker",
    props: true,
    path: "/app/settings/favorite-picker/:mode",
    meta: { requiredAuth: true },
    component: () => import("../views/pages/settings/FavoriteAccountsPicker.vue"),
  },
  {
    name: "settings-CustomReports",
    path: "/app/settings/custom-reports",
    meta: { requiredAuth: true },
    component: () => import("../views/pages/settings/CustomReports.vue"),
  },
  {
    path: "/app/folder/:id",
    props: true,
    component: () => import("../views/Folder.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// @ts-ignore
import store from "../store";
// @ts-ignore
import { SET_CAME_FROM_FULL_PATH } from "@/store/mutation-types";
router.beforeEach((to, from, next) => {
  if (!to.params.dontTrackCameFrom) {
    store.commit(SET_CAME_FROM_FULL_PATH, from.fullPath);
  }
  if (to.meta && to.meta.requiredAuth && store.getters["auth/isLoggedOut"]) {
    next({ name: "login" });
  } else {
    next();
  }
});

export default router;
