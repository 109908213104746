import { createStore } from "vuex";

const initialState = {
  cameFromFullPath: null,
};

import account from "./modules/account";
import auth from "./modules/auth";
import save from "./modules/save";

import mutations from "./mutations";
import * as getters from "./getters";
import * as actions from "./actions";

export default createStore({
  strict: true,
  state: initialState,
  getters,
  mutations,
  actions,
  modules: {
    account,
    auth,
    save,
  },
});
