export default {
  get(key) {
    try {
      return localStorage.getItem(key)
        ? JSON.parse(localStorage.getItem(key))
        : null;
    } catch (e) {
      return null;
    }
  },

  set(key, val) {
    localStorage.setItem(key, JSON.stringify(val));
  },

  remove(key) {
    localStorage.removeItem(key);
  },

  clear() {
    localStorage.clear();
  }
};
