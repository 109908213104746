import * as types from "./mutation-types.js";
import * as accountMutationTypes from "../account/mutation-types.js";

// convert last Z to +00:00 เพื่อให้ compat กับ RFC3339_EXTENDED
// เติม .milli seconds กรณีที่ไม่มี
const _solve_Z_issue = (datetime) => {
  if (datetime.match(/Z$/)) {
    datetime = datetime.replace(/Z$/, "+00:00");
  }
  const without_ms = /(:\d\d)(\+\d\d:\d\d)$/;
  if (datetime.match(without_ms)) {
    datetime = datetime.replace(without_ms, "$1.000$2");
  }

  return datetime;
}

export const loadSaves = async ({ commit }) => {
  const response = await window.axios.get("/api/saves");
  commit(types.SET_SAVES, response.data.data);
};

export const useSave = async ({ commit }, { save_id }) => {
  commit(types.SET_CURRENT_SAVE, save_id);
  commit(types.SET_RECENT_TRANSACTIONS, []);
  const response = await window.axios.post(
    `/api/saves/${save_id}/hook_after_select_save`
  );
  commit("account/" + accountMutationTypes.SET_ACCOUNTS, response.data.data, {
    root: true,
  });
};

export const createSave = async ({ dispatch }, { save_name }) => {
  const response = await window.axios.post("/api/saves", { save_name });
  if (response.data && response.data.save_id) {
    await dispatch("loadSaves");
    return response.data.save_id;
  } else {
    throw {
      response: {
        data: {
          message: "Something wrong",
          errors: {
            one: ["there is no save_id from server"],
          },
        },
      },
    };
  }
};

const _replace_save = ({ commit, state }, save) => {
  if (save.save_id) {
    const draft_saves = [...state.saves];
    for (const i in draft_saves) {
      if (draft_saves[i].save_id === save.save_id) {
        draft_saves[i] = save;
        break;
      }
    }
    commit(types.SET_SAVES, draft_saves);
  }
};

export const updateSaveFavAcc = async ({ commit, state }, { save_id, favAcc }) => {
  const payload = { favAcc };
  const response = await window.axios.put(
    `/api/saves/${save_id}/preferences/fav_accounts`,
    payload
  );
  _replace_save({ commit, state }, response.data);
};

export const createCustomReport = async ({ commit, state }, { save_id, payload }) => {
  const response = await window.axios.post(`/api/saves/${save_id}/preferences/custom_reports`, payload);
  _replace_save({ commit, state }, response.data);
};

export const updateCustomReport = async ({ commit, state }, { save_id, custom_report_id, payload }) => {
  const response = await window.axios.put(`/api/saves/${save_id}/preferences/custom_reports/${custom_report_id}`, payload);
  _replace_save({ commit, state }, response.data);
};

export const deleteCustomReport = async ({ commit, state }, { save_id, custom_report_id }) => {
  const response = await window.axios.delete(`/api/saves/${save_id}/preferences/custom_reports/${custom_report_id}`);
  _replace_save({ commit, state }, response.data);
};

export const loadReportData = async ({ commit, state }) => {
  const save_id = state.current_save_id;
  const response = await window.axios.get(`/api/saves/${save_id}/report`);
  commit(types.SET_REPORT_DATA, response.data);
};

export const loadRecentTransactions = async ({ state, commit }) => {
  const res = await window.axios.get(`/api/saves/${state.current_save_id}/recent_transactions`);
  commit(types.SET_RECENT_TRANSACTIONS, res.data.data);
};

export const deleteTransaction = async ({ state, commit }, transaction_id) => {
  const save_id = state.current_save_id;
  const response = await window.axios.delete(`/api/saves/${save_id}/transactions/${transaction_id}`);
  if (response.data.data.accounts) {
    commit(
      "account/" + accountMutationTypes.REPLACE_PARTIAL_ACCOUNTS,
      response.data.data.accounts,
      { root: true }
    );
  }
};

export const createTransaction = async ({ rootGetters, commit }, data) => {
  const save_id = rootGetters["save/currentSaveId"];

  data.datetime = _solve_Z_issue(data.datetime);

  const response = await window.axios.post(
    `/api/saves/${save_id}/transactions`,
    data
  );

  commit(types.SET_RECENT_TRANSACTIONS, response.data.data.recent_transactions);

  if (response.data.data.accounts) {
    commit(
      "account/" + accountMutationTypes.REPLACE_PARTIAL_ACCOUNTS,
      response.data.data.accounts,
      { root: true }
    );
  }

  // เก็บสถานะไว้ว่ามีการแก้ไข เวลาเข้าหน้า list assets จะได้สั่ง load accounts มาใหม่
  commit("account/" + accountMutationTypes.SET_TRANSACTION_CHANGED, true, { root: true });
};

export const updateTransaction = async ({ rootGetters, commit }, data) => {
  const save_id = rootGetters["save/currentSaveId"];

  data.datetime = _solve_Z_issue(data.datetime);

  const transaction_id = data.transaction_id;
  delete data.transaction_id;

  const response = await window.axios.put(`/api/saves/${save_id}/transactions/${transaction_id}`, data);

  if (response.data.data.accounts) {
    commit(
      "account/" + accountMutationTypes.REPLACE_PARTIAL_ACCOUNTS,
      response.data.data.accounts,
      { root: true }
    );
  }

  // เก็บสถานะไว้ว่ามีการแก้ไข เวลาเข้าหน้า list assets จะได้สั่ง load accounts มาใหม่
  commit("account/" + accountMutationTypes.SET_TRANSACTION_CHANGED, true, { root: true });
};
