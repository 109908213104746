import * as types from "./mutation-types.js";
import Ls from "../../../services/ls.js";
import * as keys from "../../../services/ls-keys.js";

export default {
  [types.SET_CURRENT_USER](state, user) {
    if (typeof user === "object" && user !== null) {
      state.currentUser = { ...user };
      Ls.set(keys.AUTH_CURRENT_USER, user);
      Ls.set(keys.ONCE_SUCCESS_LOGIN, 1);
    } else {
      state.currentUser = null;
      Ls.remove(keys.SAVE_SAVES);
      Ls.remove(keys.AUTH_CURRENT_USER);
      Ls.remove(keys.ACCOUNT_ACCOUNTS);
      Ls.remove(keys.SAVE_CURRENT_SAVE);
      Ls.remove(keys.ONCE_SUCCESS_LOGIN);
    }
  },

  [types.SET_ALREADY_CHECK_SESSION](state, flag) {
    state.isCheckSessionYet = flag;
  }
};
