import router from "./router";

import Ls from "./services/ls.js";
window.Ls = Ls;

window.axios = require("axios");
window.axios.defaults.withCredentials = true;
window.axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest"
};

window.axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      router.push({ name: "login" });
    }
    return Promise.reject(error);
  }
);
