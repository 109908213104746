<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>เลือก</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="confirm">ตกลง</ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-toolbar>
      <ion-searchbar v-model="filter"></ion-searchbar>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <ion-list>
      <template v-for="account in displayingAccounts" :key="account.account_id">
        <ion-item button detail="false" @click="toggle(account)">
          <template v-if="categoryMode">
            <ion-text color="medium">{{
              hashedCategories[account.account_id].parentString
            }}</ion-text>
          </template>
          <ion-label>
            {{ account.account_name }}
          </ion-label>
          <template v-if="selectedItems.includes(account.account_id)">
            <ion-icon
              slot="end"
              :ios="icons.selectedOutline"
              :md="icons.selectedSharp"
            ></ion-icon>
          </template>
        </ion-item>
      </template>
    </ion-list>
  </ion-content>
</template>

<script>
import {
  // Utilities
  modalController,
  // Ionic Components
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { mapGetters } from "vuex";
import { checkmarkOutline, checkmarkSharp } from "ionicons/icons";
import MixinsIonicAlert from "./MixinsIonicAlert.vue";
export default {
  name: "ModalAccountPicker",
  mixins: [MixinsIonicAlert],
  components: {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonSearchbar,
    IonText,
    IonTitle,
    IonToolbar,
  },
  data() {
    return {
      filter: "",
      selected: this.selectedItems,
    };
  },
  props: {
    selectedItems: {
      type: Array,
      required: true,
    },
    accounts: {
      type: Array,
      required: true,
    },
    categoryMode: {
      type: Boolean,
    },
    maxItems: {
      type: Number,
      required: true,
    },
    clearFilterAfterSelected: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["dismiss", "input"],
  computed: {
    ...mapGetters({
      assets: "account/currentSaveAssets",
      categories: "account/currentSaveCategories",
      hashedCategories: "account/hashedCategories",
    }),
    displayingAccounts() {
      const accounts = [...this.filteredAccounts];

      const get_full_name = (a) =>
        this.categoryMode && this.hashedCategories[a.account_id].parentString
          ? `${this.hashedCategories[a.account_id].parentString} ${a.account_name}`
          : a.account_name;

      accounts.sort((a, b) => {
        const a_name = get_full_name(a);
        const b_name = get_full_name(b);
        const a_selected = this.selected.includes(a.account_id);
        const b_selected = this.selected.includes(b.account_id);

        if (a_selected && !b_selected) return -1;
        else if (b_selected && !a_selected) return 1;
        else return b_name < a_name ? 1 : -1;
      });

      return accounts;
    },
    filteredAccounts() {
      const filter = this.filter.toLowerCase();
      return this.accounts.filter(
        (acc) =>
          !this.filter ||
          this.selected.includes(acc.account_id) ||
          acc.account_name.toLowerCase().indexOf(filter) !== -1 ||
          (this.categoryMode &&
            this.hashedCategories[acc.account_id].parentString
              .toLowerCase()
              .indexOf(filter) !== -1)
      );
    },
    icons() {
      return {
        selectedOutline: checkmarkOutline,
        selectedSharp: checkmarkSharp,
      };
    },
  },
  methods: {
    dismiss(passingValue) {
      modalController.dismiss(passingValue);
    },
    async toggle(account) {
      if (this.selected.includes(account.account_id)) {
        // remove
        this.selected.splice(this.selected.indexOf(account.account_id), 1);
      } else {
        if (this.selected.length >= this.maxItems) {
          // can't select anymore
          await this.alert({ header: `เลือกได้ไม่เกิน ${this.maxItems} รายการ`, message: "ถ้าต้องการเลือกรายการนี้ ให้ติ๊กรายการที่เลือกไว้แล้วออกก่อน" });
        } else {
          // push
          this.selected.push(account.account_id);
          // จะ clear filter หลังจากกดเลือกมั้ย
          if (this.clearFilterAfterSelected) {
            this.filter = "";
          }
        }
      }
    },
    confirm() {
      this.dismiss(this.selected);
    },
  },
};
</script>

<style scoped></style>
