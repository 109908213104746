import mutations from "./mutations";
import * as getters from "./getters";
import * as actions from "./actions";
import Ls from "../../../services/ls.js";
import * as keys from "../../../services/ls-keys.js";

const initialState = {
  currentUser: Ls.get(keys.AUTH_CURRENT_USER), // ถ้าไม่ logged-in จะเป็น false
  isCheckSessionYet: false, // ผ่านการ checkSession ครั้งแรกแล้วรึยัง
};

export default {
  namespaced: true,
  state: () => initialState,
  getters,
  mutations,
  actions
};
