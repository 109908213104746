import * as types from "./mutation-types.js";
import router from "../../../router";
import Ls from "../../../services/ls.js";
import * as keys from "../../../services/ls-keys.js";

export const logout = ({ commit }) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post("/api/logout")
      .then(response => {
        if (!response.data || response.data.status !== "ok") {
          reject("LOGOUT_FAILED");
        }
      })
      .catch(error => {
        reject(error);
      })
      .finally(() => {
        commit(types.SET_CURRENT_USER, null);
        router
          .push({ name: "login" })
          .then(resolve)
          .catch(reject);
      });
  });
};

export const redirectToLnwAccount = () => {
  window.location.href = "/auth/lnwaccounts/redirect";
};

export const checkSession = async ({ commit, dispatch }) => {
  try {
    const response = await window.axios.get("/api/user");
    commit(types.SET_CURRENT_USER, response.data);
    commit(types.SET_ALREADY_CHECK_SESSION, true);
    if (router.currentRoute.value.name === "login") {
      router.push({ name: "home" });
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      if (Ls.get(keys.ONCE_SUCCESS_LOGIN)) {
        // ถ้าเคยใช้งาน จะลอง redirect ไปที่ lnwaccounts ดูก่อน เพื่อให้วิ่งกลับมา แล้วใช้งานต่อทันที
        Ls.remove(keys.ONCE_SUCCESS_LOGIN); // ต้องเคลียออกเพื่อกัน redirect loop
        return dispatch("redirectToLnwAccount");
      } else {
        commit(types.SET_ALREADY_CHECK_SESSION, true);
        return dispatch("logout");
      }
    } else {
      commit(types.SET_ALREADY_CHECK_SESSION, true);
      throw error;
    }
  }
};
