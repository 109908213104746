import { isCategory } from "../../../helper.js";
import * as CS from "../save/constants.js";

const num_of_suggestion = 5;

// บัญชีทั้งหมด
export const assets = state => {
  return state.accounts.filter(account => account.account_type === 1);
};

// หมวดหมู่ทั้งหมด
export const categories = state => {
  return state.accounts.filter(isCategory);
};

export const hashedCategories = (state, getters) => {
  return Object.keys(getters.hashedAccounts)
    .filter((key) => isCategory(getters.hashedAccounts[key]))
    .reduce((carry, key) => {
      carry[key] = getters.hashedAccounts[key];
      return carry;
    }, {});
};

export const rootCategories = (state, getters) => {
  return getters.categories.filter((cat) => cat._hierarchy_parent_id === 0);
};

export const hashedAccounts = (state) => {
  const hashedAccounts = {};
  state.accounts.forEach((acc) => {
    hashedAccounts[acc.account_id] = { ...acc, parentString: "" };
  });
  state.accounts.forEach((acc) => {
    if (acc._hierarchy_parent_id) {
      let p = hashedAccounts[acc._hierarchy_parent_id];
      while (p) {
        hashedAccounts[acc.account_id].parentString =
          p.account_name + " > " + hashedAccounts[acc.account_id].parentString;
        p = p._hierarchy_parent_id
          ? hashedAccounts[p._hierarchy_parent_id]
          : false;
      }
    }
  });
  return hashedAccounts;
};

// บัญชี ของ folder ที่กำลังใช้งาน
export const currentSaveAssets = (state, getters, rootState) => {
  return getters.assets.filter(
    account => account.save_id === rootState.save.current_save_id
  );
};

// หมวดหมู่ ของ folder ที่กำลังใช้งาน
export const currentSaveCategories = (state, getters, rootState) => {
  return getters.categories.filter(
    account => account.save_id === rootState.save.current_save_id
  );
};

export const currentSaveAssetsCount = (state, getters) => getters.currentSaveAssets.length;
export const currentSaveCategoriesCount = (state, getters) => getters.currentSaveCategories.length;

const _suggestion_calc = ({ getters, rootGetters, favAccConst, fallback }) => {
  const fav = rootGetters["save/currentSavePrefFavAcc"][favAccConst];
  if (fav && Array.isArray(fav) && fav.length) {
    const accounts = [];
    fav.forEach((id) => {
      accounts.push(getters.hashedAccounts[id]);
    });
    return accounts;
  } else {
    return fallback();
  }
};

export const suggestedAssetsForWithdraw = (state, getters, rootState, rootGetters) => {
  return _suggestion_calc({
    getters,
    rootGetters,
    favAccConst: CS.PREF_FAVACC_ASSET_FOR_EXPENSE,
    fallback: () => {
      const forSort = [...getters.assets];
      return forSort
        .sort((a, b) => b.cache_count_withdraw - a.cache_count_withdraw)
        .slice(0, num_of_suggestion);
    },
  });
};

export const suggestedAssetsForDeposit = (state, getters, rootState, rootGetters) => {
  return _suggestion_calc({
    getters,
    rootGetters,
    favAccConst: CS.PREF_FAVACC_ASSET_FOR_INCOME,
    fallback: () => {
      const forSort = [...getters.assets];
      return forSort
        .sort((a, b) => b.cache_count_deposit - a.cache_count_deposit)
        .slice(0, num_of_suggestion);
    },
  });
};

export const suggestedAssetsForTransferFrom = (state, getters, rootState, rootGetters) => {
  return _suggestion_calc({
    getters,
    rootGetters,
    favAccConst: CS.PREF_FAVACC_ASSET_FOR_FROM,
    fallback: () => {
      const forSort = [...getters.assets];
      return forSort
        .sort((a, b) => b.cache_count_transfer_debit - a.cache_count_transfer_debit)
        .slice(0, num_of_suggestion);
    },
  });
};

export const suggestedAssetsForTransferTo = (state, getters, rootState, rootGetters) => {
  return _suggestion_calc({
    getters,
    rootGetters,
    favAccConst: CS.PREF_FAVACC_ASSET_FOR_TO,
    fallback: () => {
      const forSort = [...getters.assets];
      return forSort
        .sort((a, b) => b.cache_count_transfer_credit - a.cache_count_transfer_credit)
        .slice(0, num_of_suggestion);
    },
  });
};

export const suggestedCategoriesForWithdraw = (state, getters, rootState, rootGetters) => {
  return _suggestion_calc({
    getters,
    rootGetters,
    favAccConst: CS.PREF_FAVACC_CATEGORY_FOR_EXPENSE,
    fallback: () => {
      const forSort = [...getters.categories];
      return forSort
        .sort((a, b) => b.cache_count_withdraw - a.cache_count_withdraw)
        .slice(0, num_of_suggestion);
    },
  });
};

export const suggestedCategoriesForDeposit = (state, getters, rootState, rootGetters) => {
  return _suggestion_calc({
    getters,
    rootGetters,
    favAccConst: CS.PREF_FAVACC_CATEGORY_FOR_INCOME,
    fallback: () => {
      const forSort = [...getters.categories];
      return forSort
        .sort((a, b) => b.cache_count_deposit - a.cache_count_deposit)
        .slice(0, num_of_suggestion);
    },
  });
};
