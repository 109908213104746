import * as types from "./mutation-types.js";
import * as keys from "../../../services/ls-keys.js";
import Ls from "../../../services/ls.js";

export default {
  [types.SET_SAVES](state, saves) {
    if (typeof saves === "object" && saves !== null) {
      state.saves = [...saves];
      Ls.set(keys.SAVE_SAVES, saves);
    } else {
      state.saves = [];
      Ls.remove(keys.SAVE_SAVES);
    }
  },

  [types.SET_CURRENT_SAVE](state, save_id) {
    if (save_id) {
      state.current_save_id = save_id;
      Ls.set(keys.SAVE_CURRENT_SAVE, save_id);
    } else {
      Ls.remove(keys.SAVE_CURRENT_SAVE);
    }
  },

  [types.SET_RECENT_TRANSACTIONS](state, recent_transactions) {
    state.recent_transactions = [...recent_transactions];
  },

  [types.SET_REPORT_DATA](state, report_data) {
    state.report_data = { ...report_data };
  },
};
